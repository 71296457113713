import { BadgeType, EGainsPriority, EPreferredUptBaseTypes } from '@/enums';
import { EMedicalClearanceStatus } from '@/enums/EMedicalClearance';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { GroupInfo } from '@/models/Entities/Student/Constants';
import { formatDate } from '@/services/formatService';
import { dateDiffFromNow } from '@/util/date';
import { DisplayNameUtil } from '@/util/displayName';
import { BooleanUtils } from '../Common/util/boolean-utils';
import { SsnUtils } from '../Common/util/ssn-utils';

export class GainsReportTableData {
  id!: number;
  badge!: BadgeType | null;
  idle!: number;
  medicalClearanceStatus!: EMedicalClearanceStatus | null;
  step!: string;
  groupInfo!: GroupInfo | null;
  name!: string | null;
  candidate!: BaseCandidate;

  priority!: EGainsPriority;
  inProStartDate!: string | null;
  poc?: string | null;
  pocPhone?: number | null;
  hasPrivatePilotLicense!: string | null;
  basePreference!: EPreferredUptBaseTypes | null;
  socialSecurityNumber!: string | null;
  civilianPathToWings!: string | null;
  securityClearance!: string | null;
  availableTrainingStartDate!: string | null;

  constructor(gains: Gains) {
    this.id = gains.candidateId;
    this.badge = gains.badge;
    this.idle = dateDiffFromNow(gains.studentUftBoardDateSelected);
    this.medicalClearanceStatus = gains.medicalClearanceStatus;
    this.step = gains.step + '/7';
    this.groupInfo = gains.groupInfo;
    this.name = DisplayNameUtil.flattenName(
      gains.firstName.toUpperCase(),
      gains.lastName.toUpperCase()
    );
    this.candidate = gains.candidate;
    this.priority = gains.priority;
    if (gains.inProStartDate) {
      this.inProStartDate = formatDate(gains.inProStartDate);
    }
    this.poc = gains.poc;
    this.pocPhone = gains.pocPhone;
    this.hasPrivatePilotLicense = BooleanUtils.booleanToStringReturnValue(
      gains.hasPrivatePilotLicense
    );
    this.basePreference = gains.basePreference;
    this.socialSecurityNumber = SsnUtils.ConvertToReadableSSN(
      gains.socialSecurityNumber
    );
    this.civilianPathToWings = BooleanUtils.booleanToStringReturnValue(
      gains.civilianPathToWings
    );
    this.securityClearance = gains.securityClearance;
    if (gains.availableTrainingStartDate) {
      this.availableTrainingStartDate = formatDate(
        gains.availableTrainingStartDate
      );
    }
  }
}
