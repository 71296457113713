
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from './IconComponent.vue';
@Component<IconTextComponent>({
  components: {
    IconComponent
  }
})
export default class IconTextComponent extends Vue {
  @Prop()
  displayString?: string;

  @Prop()
  icon!: string;

  @Prop()
  readonly fill?: string;

  @Prop()
  readonly stroke?: string;
}
