
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LinkElementComponent extends Vue {
  @Prop()
  displayString?: string;

  @Prop({ default: 'val-button-blue' })
  readonly color!: string;

  @Prop({ required: true })
  linkTo!: string | Location;

  get textColor() {
    return Object.keys(this.linkTo).length ? 'text-' + this.color : '';
  }
  get linkEffect() {
    return Object.keys(this.linkTo).length
      ? 'underline cursor-pointer'
      : 'cursor-default';
  }
}
