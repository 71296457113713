var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "router-link",
        { class: [_vm.textColor, _vm.linkEffect], attrs: { to: _vm.linkTo } },
        [_vm._v(_vm._s(_vm.displayString))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }