var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    attrs: {
      readonly: "",
      type: "tel",
      name: "PhoneNumber",
      pattern: "[0-9]{3}-[0-9]{2}-[0-9]{3}",
    },
    domProps: { value: _vm.formattedValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }