import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { TableDataProvider } from '../models/TableDataProvider';
import { GainsReportTableData } from './GainsReportTableData';

export class GainsReportDataProvider extends TableDataProvider<
  GainsReportTableData,
  Gains,
  SearchGainsDto
> {
  protected transformer = (t: Gains): GainsReportTableData =>
    new GainsReportTableData(t);

  protected queryHandler = async (
    searchDto: SearchGainsDto
  ): Promise<SearchResponseDto<Gains>> => {
    const results: SearchResponseDto<Gains> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'gains', query: searchDto, model: Gains }
    );
    return results;
  };
}
