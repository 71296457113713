var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "p-4 bg-gray-100" },
    [
      _c(
        "div",
        { staticClass: "inline-flex" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: _vm.routes.REPORTS_DASHBOARD } } },
            [
              _c("h3", { staticClass: "pt-4 pl-8 mr-2 text-gray-500" }, [
                _vm._v("Reports"),
              ]),
            ]
          ),
          _c("h3", { staticClass: "pt-4 mr-2" }, [_vm._v("/ Gains Priority")]),
        ],
        1
      ),
      _c(
        "page-layout",
        [
          _vm.searchService
            ? _c(
                "async-table-component",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        isSortable: true,
                        isFilterable: true,
                        canDownloadCSV: true,
                        localStorageKey: "ots-class-collection-table",
                        emptyTableMessage: "0 Gains Priority Candidates",
                      },
                    },
                    "async-table-component",
                    _vm.searchService.tableProps,
                    false
                  ),
                  _vm.searchService.tableEventListeners
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }