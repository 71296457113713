import { BadgeType, EGainsPriority, EPreferredUptBaseTypes } from '@/enums';
import { EMedicalClearanceStatus } from '@/enums/EMedicalClearance';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import {
  GroupInfo,
  SecurityClearance
} from '@/models/Entities/Student/Constants';
import { NumberRange } from '@/models/NumberRange';
import {
  booleanFilterComponentDataBuilder,
  dateFilterComponentDataBuilder,
  enumFilterComponentDataBuilder,
  numberRangeFilterComponentDataBuilder
} from '@/models/Table/Table';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 * @param string the label showing in the drop down options
 */
export const gainsReportBadgeFilter = enumFilterComponentDataBuilder(
  'Badge',
  (t: SearchGainsDto, value: string) => {
    t.badgeType = <BadgeType>value;
  },
  BadgeType
);

export const gainsReportStatusFilter = numberRangeFilterComponentDataBuilder(
  'Status',
  (t: SearchGainsDto, value: NumberRange) => {
    t.statusMin = value.minValue;
    t.statusMax = value.maxValue;
  },
  { min: 0, max: 7 }
);

export const gainsReportPriorityFilter = enumFilterComponentDataBuilder(
  'Priority',
  (t: SearchGainsDto, value: string) => {
    t.priority = <EGainsPriority>value;
  },
  EGainsPriority
);

export const gainsReportInProFilter = dateFilterComponentDataBuilder(
  'InPro',
  (t: SearchGainsDto, value: Date) => {
    t.inProGroup = value;
  }
);

export const gainsReportGroupInfoFilter = enumFilterComponentDataBuilder(
  'Group',
  (t: SearchGainsDto, value: string) => {
    t.groupInfo = <GroupInfo>value;
  },
  GroupInfo
);

export const gainsReportMedicalStatusFilter = enumFilterComponentDataBuilder(
  'Medical Clearance',
  (t: SearchGainsDto, value: string) => {
    t.medicalStatus = <EMedicalClearanceStatus>value;
  },
  EMedicalClearanceStatus
);

export const gainsReportSecurityStatusFilter = enumFilterComponentDataBuilder(
  'Security Clearance',
  (t: SearchGainsDto, value: string) => {
    t.securityStatus = <SecurityClearance>value;
  },
  SecurityClearance
);

export const gainsReportPPLFilter = booleanFilterComponentDataBuilder(
  'PPL',
  (dto: SearchGainsDto, value: boolean) => (dto.ppl = value)
);

export const gainsReportCPWFilter = booleanFilterComponentDataBuilder(
  'CPW',
  (dto: SearchGainsDto, value: boolean) => (dto.cpw = value)
);

export const gainsReportBaseOfPrefFilter = enumFilterComponentDataBuilder(
  'Base of Preference',
  (t: SearchGainsDto, value: string) => {
    t.baseOfPref = <EPreferredUptBaseTypes>value;
  },
  EPreferredUptBaseTypes
);
