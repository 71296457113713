
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import Routes from '@/router/Routes';
import { GainsReportTableConfigBuilder } from '@/components/Table/GainsReport/GainsReportTableConfigBuilder';
import { GainsReportDataProvider } from '@/components/Table/GainsReport/GainsReportDataProvider';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import { GainsReportTableData } from '@/components/Table/GainsReport/GainsReportTableData';
import {
  gainsReportBadgeFilter,
  gainsReportBaseOfPrefFilter,
  gainsReportCPWFilter,
  gainsReportGroupInfoFilter,
  gainsReportInProFilter,
  gainsReportMedicalStatusFilter,
  gainsReportPPLFilter,
  gainsReportPriorityFilter,
  gainsReportSecurityStatusFilter,
  gainsReportStatusFilter
} from '@/components/Table/GainsReport/GainsReportFilters';
@Component<OtsClassesPage>({
  components: {
    ButtonComponent,
    IconComponent,
    PageLayout,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class OtsClassesPage extends Vue {
  routes = Routes;

  searchService: TableSearchService<
    GainsReportTableData,
    Gains,
    never,
    SearchGainsDto
  > | null = null;

  async created(): Promise<void> {
    this.searchService = new TableSearchService<
      GainsReportTableData,
      Gains,
      never,
      SearchGainsDto
    >(
      this.$store,
      new GainsReportDataProvider(this.$store),
      {},
      { defaultSortProperty: 'lastName' },
      GainsReportTableConfigBuilder,
      {
        filters: [
          gainsReportBadgeFilter,
          gainsReportStatusFilter,
          gainsReportPriorityFilter,
          gainsReportInProFilter,
          gainsReportGroupInfoFilter,
          gainsReportMedicalStatusFilter,
          gainsReportSecurityStatusFilter,
          gainsReportPPLFilter,
          gainsReportCPWFilter,
          gainsReportBaseOfPrefFilter
        ],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
  }
}
