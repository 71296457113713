import { BadgeType, EGainsPriority, EPreferredUptBaseTypes } from '@/enums';
import { EMedicalClearanceStatus } from '@/enums/EMedicalClearance';
import { removeTimeZoneOffset } from '@/util/class-transformer/removeTimeZoneOffset';
import { plainToClass, Transform, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';
import { GroupInfo, SecurityClearance } from '../Student/Constants';

export class Gains {
  @Type(/* istanbul ignore next */ () => Number)
  candidateId!: number;
  @Type(/* istanbul ignore next */ () => String)
  firstName!: string;
  @Type(/* istanbul ignore next */ () => String)
  lastName!: string;
  badge!: BadgeType | null;
  medicalClearanceStatus!: EMedicalClearanceStatus | null;
  @Type(/* istanbul ignore next */ () => Number)
  step!: number;
  groupInfo!: GroupInfo | null;
  @Type(/* istanbul ignore next */ () => Date)
  @Transform(
    /* istanbul ignore next */ ({ value }) => removeTimeZoneOffset(value)
  )
  studentUftBoardDateSelected!: Date;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  candidate!: BaseCandidate;
  priority!: EGainsPriority;
  inProStartDate!: Date | null;
  poc?: string | null;
  pocPhone?: number | null;
  hasPrivatePilotLicense!: boolean | null;
  basePreference!: EPreferredUptBaseTypes | null;
  socialSecurityNumber!: string | null;
  civilianPathToWings!: boolean | null;
  availableTrainingStartDate!: Date | null;
  securityClearance!: SecurityClearance | null;

  constructor(partial?: Partial<Gains>) {
    if (partial) {
      Object.assign(this, plainToClass(Gains, partial));
    }
  }
}
