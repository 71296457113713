
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PhoneNumberTableComponent extends Vue {
  @Prop()
  readonly number?: number;

  get formattedValue(): string {
    const x = this.number
      ?.toString()
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || [''];
    if (!x[2]) {
      return x[1];
    } else {
      return '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
  }
}
