import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import { GainsReportTableData } from './GainsReportTableData';
import { Gains } from '@/models/Entities/MaterializedViews/Gains';
import { SearchGainsDto } from '@/models/Dtos/searchGainsDto';
import PhoneNumberTableComponent from '@/components/Table/Elements/PhoneNumberTableComponent.vue';
import IconTextComponent from '@/components/IconTextComponent.vue';
import { priorityColorMap } from '@/enums';

export const GainsReportTableConfigBuilder: TableConfigBuilder<
  GainsReportTableData,
  Gains,
  never,
  SearchGainsDto
> = (): ITableConfiguration<GainsReportTableData, Gains, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (rowData) => {
            return {
              displayString: rowData.name,
              linkTo: {
                name: Routes.APPLICANT_DETAILS,
                params: { id: rowData.id }
              }
            };
          }
        },
        sortKey: 'lastName'
      },
      {
        columnTitle: 'SSN',
        render: 'socialSecurityNumber'
      },
      {
        columnTitle: 'Badge',
        render: 'badge',
        sortKey: 'badge'
      },
      {
        columnTitle: 'Idle',
        render: 'idle',
        sortKey: 'studentUftBoardDateSelected'
      },
      {
        columnTitle: 'Status',
        render: 'step',
        sortKey: 'step'
      },
      {
        columnTitle: 'Priority',
        render: {
          componentRef: IconTextComponent,
          componentProps: (
            rowData: GainsReportTableData
          ): Record<string, unknown> => {
            const fill = priorityColorMap[rowData.priority];
            return {
              fill,
              icon: 'circleFilled',
              displayString: rowData.priority
            };
          }
        },
        sortKey: 'priority'
      },
      {
        columnTitle: 'InPro',
        render: 'inProStartDate',
        sortKey: 'inProStartDate'
      },
      {
        columnTitle: 'Group',
        render: 'groupInfo',
        sortKey: 'groupInfo'
      },
      {
        columnTitle: 'Medical Clearance',
        render: 'medicalClearanceStatus',
        sortKey: 'medicalClearanceStatus'
      },
      {
        columnTitle: 'Security Clearance',
        render: 'securityClearance',
        sortKey: 'securityClearance'
      },
      {
        columnTitle: 'PPL',
        render: 'hasPrivatePilotLicense',
        sortKey: 'hasPrivatePilotLicense'
      },
      {
        columnTitle: 'CPW',
        render: 'civilianPathToWings',
        sortKey: 'civilianPathToWings'
      },
      {
        columnTitle: 'Base of Pref',
        render: 'basePreference',
        sortKey: 'basePreference'
      },
      {
        columnTitle: 'Available',
        render: 'availableTrainingStartDate',
        sortKey: 'availableTrainingStartDate'
      },
      {
        columnTitle: 'POC',
        render: 'poc',
        sortKey: 'poc'
      },
      {
        columnTitle: 'POC Phone',
        render: {
          componentRef: PhoneNumberTableComponent,
          componentProps: (rowData): Record<string, unknown> => {
            return {
              number: rowData.pocPhone
            };
          }
        },
        sortKey: 'pocPhone'
      }
    ]
  };
};
