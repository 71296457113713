export class SsnUtils {
  public static ConvertToReadableSSN(ssn: string | null): string {
    let [field1, field2, field3] = ['', '', ''];
    if (ssn && ssn.length === 9) {
      field1 = ssn.substring(0, 3);
      field2 = ssn.substring(3, 5);
      field3 = ssn.substring(5, 9);
    }
    return field1 + '-' + field2 + '-' + field3;
  }
}
